.welcome-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex: 1;

  justify-content: center;
  align-items: center;

  background-color: #2a78ff;
  /* background-image: url('///image.siuvo.com.cn/wechat/h5TitleBackgroundImage.jpeg'); */
  background-image: url('///image.siuvo.com.cn/wechat/h5TitleBackgroundImage_nosmhc.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

/* .login-screen-content{
  padding-top: 50px;
} */
.welcome-container-body {
  /* height: 100vh;
  width: 100vw; */
}

.welcome-shufu-logo {
  padding: 16px;
  width: 30%;
  max-width: 200px;
}

.welcome-words {
  display: flex;
  align-items: center;
  padding: 16px;
}

.welcome-words-text {
  font-size: 28px;
  font-weight: bold;
}

.welcome-words-image {
  width: 100px;
  max-width: 100px;
  transform: rotate(20deg);
  -ms-transform: rotate(20deg);
  /* IE 9 */
  -moz-transform: rotate(20deg);
  /* Firefox */
  -webkit-transform: rotate(20deg);
  /* Safari 和 Chrome */
  -o-transform: rotate(20deg);
}

.welcome-title {
  color: white;
  margin-bottom: 250px;
  max-width: 250px;
  text-align: center;
  font-size: 20px;
}

.welcome-login-button {
  margin: 16px;
}

/*
覆盖label样式
*/
.welcome-container-body .list .item-floating-label,
.list .item-label {
  color: #136cfd
}

.login-screen-page .login-screen-content,
.login-screen-page .page-content,
.login-screen>.page .login-screen-content,
.login-screen>.page .page-content,
.login-screen>.view>.page .login-screen-content,
.login-screen>.view>.page .page-content {
  /* top: 15%;
  position: absolute; */
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  height: auto;
  max-height: 100%;
  width: 100%;
}