.answerBoard-wcst {
  /* display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 0 10px;
  justify-content: space-around; */

}

.answerBoard-wcst-img {
  max-width: 140px;
  cursor: pointer;
  width: 100%;
  flex: 1;
  -ms-flex: 1;
  border-radius: 10px;
  background: #f8f8f8;
  padding: 4px;
}