.CategorySymptomItem .item-content .item-title {
  white-space: normal;
}

/* 组件兼容性问题会导致IOS手机有时不显示下边框 */
li.CategorySymptomItem {
  border-bottom: 1px solid #dcdcdc66;
  box-sizing: border-box;
}

.CategorySymptomItem .list .item-inner:after {
  height: 0;
}

.CategorySymptomItem-selected {
  background-color: #f4f4f4
}