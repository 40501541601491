.horizontal-no-padding div {
  padding-left: 0!important;
  padding-right: 0!important;
}

.horizontal-no-padding textarea {
  /* padding-left: 0!important;
  padding-right: 0!important; */
  border: 1px solid gainsboro;
  padding: 10px;
}

.demo-popup {
  overflow: auto;
}

.bug-report-checkbox {
  margin: 10px 0;
}

.bug-report-checkbox>label {
  display: flex;
}

.bug-report-checkbox>label i {
  margin-right: 8px;
}

li {
  list-style-type: none;
}