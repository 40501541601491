.btn-div {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  border-top: 1px solid cornflowerblue;
  z-index: 999;
}

.text-button {
  flex: 2;
  line-height: 60px;
  text-align: left;
  padding-left: 20px;
  color: red;
  background-color: white;
}

.submit-button {
  flex: 1;
  color: white;
  background-color: cornflowerblue;
  line-height: 60px;
  text-align: center;
}

.form-info {
  padding: 0 20px 10px;
  font-size: 16px;
}

.form-info-title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
}

.form-name {
  flex: 6;
  word-break: break-all;
}

.nav-title {
  margin: 20px;
  padding-left: 10px;
  font-weight: bold;
  font-size: 22px;
  border-left: 5px solid cornflowerblue;
}

.content-div {
  position: absolute;
  top: 50px;
  bottom: 62px;
  width: 100%;
  overflow: auto;
}