.welcome-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex: 1;

  justify-content: center;
  align-items: center;

  background-color: #2a78ff;
  /* background-image: url('///image.siuvo.com.cn/wechat/h5TitleBackgroundImage.jpeg'); */
  background-image: url('///image.siuvo.com.cn/wechat/h5TitleBackgroundImage_nosmhc.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.smhc-left-icon {
  position: absolute;
  top: 0px;
  left: 0;
  z-index: -1;
  display: none;
}
.smhc-right-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.welcome-smhc-shufu-logo {
  padding: 16px;
  width: 30%;
}
.welcome-words {
  position: relative;
  padding: 16px;
}
.welcome-smhc-words-text {
  font-size: 30px;
  /* font-weight: bold */
}
.welcome-content{
  margin-top: 10%!important;
}
.welcome-childcare-container-body .login-screen-content{
  display: inline!important;
}
.welcome-smhc-shufu-words-image {
  position: absolute;
  top: auto;
  left: 60%;
  width: 128px;
  /* transform:rotate(20deg);
  -ms-transform:rotate(20deg); 	
  -moz-transform:rotate(20deg); 
  -webkit-transform:rotate(20deg);
  -o-transform:rotate(20deg); 
   */
  z-index: -1;
}
.welcome-title {
  color: white;
  margin-bottom: 250px;
  max-width: 250px;
  text-align: center;
  font-size: 20px;
}
.welcome-login-button {
  margin: 16px;
}
.welcome-copyright {
  text-align: 'center';
  font-size: '14px';
  color: '#8c8c8c';
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 20px;
}
/*
覆盖label样式
*/
.welcome-container-body .list .item-floating-label, .list .item-label {
  color: #136cfd
}

.welcome-childcare-container-body .login-screen-page,
.welcome-childcare-container-body  .login-screen-content, 
.welcome-childcare-container-body .login-screen-page .page-content, 
.welcome-childcare-container-body .login-screen>.page .login-screen-content,
.welcome-childcare-container-body .login-screen>.page .page-content,
.welcome-childcare-container-body .login-screen>.view>.page .login-screen-content,
.welcome-childcare-container-body .login-screen>.view>.page .page-content {
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    max-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
}