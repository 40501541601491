.register {
  padding: 60px 30px;
  height: 100vh;
}

.register-title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #2979ff;
}

.register-article {
  font-size: 16px;
  margin-bottom: 30px;
  line-height: 25px;
  font-weight: 500;
  text-align: justify;
}

.input-desc {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
}

.input-item {
  display: flex;
  flex-direction: row;
  -ms-flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.color-blue {
  color: #2979ff;
}