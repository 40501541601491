.scaleIntroductionSection {
  text-align: center;
  background-color: white;
}

.pageContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.contentSection {
  text-align: justify;
  padding-left: 35px;
  padding-right: 35px;
}

.scaleImg {
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
  height: 61vw;
  max-height: 300px;
}

.scaleTitle {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #414141;
  letter-spacing: -0.58px;
  text-align: center;
  margin-top: 25px;
}

.scaleIntroduction {
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: #000000;
  letter-spacing: 2.04px;
  text-align: left;
}

.buttonSection {
  text-align: center;
  padding-left: 0%;
  padding-top: 30px;
}

.startTestButton {
  background: #2979ff;
  border-radius: 0;
  width: 100%;
  height: 48px;
  border-width: 0;
}

.buttonTip {
  font-family: PingFangSC-Semibold;
  font-size: 20px;
  color: #ffffff;
  letter-spacing: -0.39px;
  text-align: center;
}
