.body-title {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin: 10px auto;
}

.body-content {
  font-size: 1.5em;
  margin: 20px 0px;
}
.body-content-joint {
  font-size: 1.5em;
  margin: 10px 0px 15px 0;
}

.body-part {
  text-align: center;
}

.highlight {
  color: white;
  font-size: 1.2em;
  background-color: #2979ff;
  padding: 7px 15px;
}

.no-highlight {
  color: white;
  font-size: 1.2em;
  background-color: #d2d2d2;
  padding: 7px 15px;
}

.body-part-joint-svg {
  width: 100% !important;
  height: 100% !important;
}

.body-part-each-joint {
  text-align: center;
  margin-bottom: 10px;
  padding: 5px 2px;
  background-color: #2979ff;
  color: white;
  border-radius: 5px;
}

.body-part-each-joint-active {
  background-color: pink;
}

.body-information-confirm {
  position: fixed;
  top: 10vh;
  bottom: 10vh;
  left: 10vw;
  right: 10vw;
  border: 1px solid gainsboro;
  background-color: white;
}

.body-information-confirm-imgs {
  overflow: auto;
  height: 80%;
}

.body-information-confirm-text {
  padding: 20px 10px;
  font-size: 18px;
  color: #2979ff;
}

.body-information-confirm-btns {
  display: flex;
}

.body-information-confirm-btn {
  flex: 1;
  -ms-flex: 1;
  padding: 10px 0;
  text-align: center;
}

.body-information-confirm-btn-cancel {
  border: 1px solid gainsboro;
  color: #2979ff;
  background-color: white;
}

.body-information-confirm-btn-ok {
  background-color: #2979ff;
  color: white;
}