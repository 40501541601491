.symptom-search-popup-selected-container {
  display: flex;
  flex-direction: column;

  /* overflow: hidden; */
  overflow-y: auto;
  transition: all 0.3s linear;
  box-sizing: content-box;
  max-height: 20vh;
}

.symptom-search-popup-max-select-tip {
  margin-left: 24px;
  font-size: 10px;
  margin-top: 8px;
  color: #8c8c8c;
  letter-spacing: 0.36px;
}

.symptom-search-popup-selected-items-container {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 7px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.symptom-search-popup-selected-items {
  background: #deeaff;
  border: 1px solid #2979ff;
  border-radius: 2em;
  min-width: 70px;
  margin: 6px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #2979ff;
  padding: 7px 17px;
  box-sizing: border-box;
}

.symptom-search-popup-selected-items-delete-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background: white;
  border-radius: 10px;
}

.symptom-search-popup-tabs-container {
  flex: 1;
  /* background-color: yellow; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.symptom-search-popup-tabs-container .tabs {
  top: 0;
  flex: 1;
  height: 100%;
  /* --f7-page-toolbar-top-offset: 0; */
  /* padding-bottom: 44px; */
}
.symptom-search-popup-tabs-container .tab {
  overflow-y: auto;
  padding: 0px 10px 50px 10px; /*iPhone Bug, set padding bottom larger */
}
.symptom-search-popup-tabs-container .tab-active {
  /* display: flex; */
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.symptom-search-popup-category-tabbar {
  top: 0;
}
.symptom-search-popup-category-tabbar .toolbar-inner {
  background-color: white;
}
.symptom-search-popup-category-tabbar .tab-link {
  color: #8c8c8c;
  /* box-shadow: inset -1px 0 0 0 #dcdcdc; */
  /* line-height: 10px; */
}

.symptom-search-popup-category-tabbar .tab-link-active {
  box-shadow: 0px -3px 0px #2979ff inset;
  color: #2979ff;
}

.symptom-search-popup-tabs-container .shufu-symptom-search-button-selected {
  background: #f9f9f9;
  color: #8c8c8c;
  border-color: #f9f9f9;
}

/* .symptom-search-popup-suggest-container .shufu-symptom-search-button-selected {
  background: #f9f9f9;
  color: #8c8c8c;
  border-color: #f9f9f9;

} */

.symptom-search-popup-suggest-container {
  margin: 5px 15px;
  padding: 10px;
  border-radius: 10px;
  background: #f9f9f9;
  max-height: 10vh;
  overflow-y: scroll;
}

.symptom-search-popup-sugggest-title-wrapper {
  /* background: #2979ff; */
  padding: 2px 10px 2px 10px;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 2px;
}

.symptom-search-popup-sugggest-title-text {
  font-weight: 900
}
/* 右上角提交按钮样式修改 */
.symptom-search-submit-button {
  background-color: #2979ff;
  color: #fff;
  padding: 0 13px;
  border-radius: 20px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}
/* 点击添加更多症状按钮 */
.symptom-search-add-more {
  background-color: #deeaff;
  border-radius: 15px;
  width: 215px;
  height: 30px;
  line-height: 30px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 30px;
  margin: auto;
  z-index: 1;
  text-align: center;
  transform: translate3d(0,0,0);   /*iphone bug, need this to support position:fixed */

}