.object-type-answer-default {
  font-size: 17px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.object-type-answer-column {
  font-size: 17px;
  margin-left: 5em;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
