.scalesSection {
  background-color: white;
  text-align: center;
}

.card {
  padding-bottom: 1px;
}

button {
  height: auto!important;
}

.scaleButtons {
  text-align: center;
  background-color: white;
  border: 0;
  padding: 0;
}

.scaleButtonTips {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #212121;
  /* letter-spacing: -0.34px; */
  text-align: left;
  padding-left: 16%;
  margin-top: 8px;
  margin-bottom: 8px;
}

.scaleImage {
  border-radius: 5px;
  width: 100%;
  text-align: center;
  max-height: 150px;
  object-fit: cover;
}

.ul-reportButton {
  text-align: center;
  padding: 0 8px 0 8px;
}

.historyReportButton {
  background: #2979ff;
  border-radius: 6px;
}

.historyReportButtonTip {
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: -0.34px;
  padding-left: 10px;
  text-align: left;
}

.historyReportButtonTip:after {
  float: right;
  padding-right: 10px;
  content: '>';
}
