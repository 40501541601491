.reportPage {
  background-color: #f9f9f9;
}

.report-section {
  border-radius: 8px;
  margin: 20px;
  padding: 20px;
  background-color: white;
  font-size: 1.2em;
  text-align: justify;
  line-height: 1.5;
  color: #414141;
}

.report-title-container {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.report-title-text {
  font-family: "Microsoft YaHei", "微软雅黑", "Helvetica", "Hiragino Sans GB",
    "黑体", "Arial", "SiYuanHeiTi", "sans-serif";
  font-weight: 900;
  letter-spacing: 5px;
  font-size: 2.4em;
  color: #2979ff;
}

.report-title-text {
  display: inline;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 65%, #fee23c 65%);
}

.report-title-subtext {
  text-align: center;
  font-size: 12px;
  color: #999999;
  margin-top: 10px;
}

.report-graph-para-title-text {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.share-chart-text-container {
  margin-left: 10px;
}

.share-chart-icon {
  align-self: center;
}

.share-icon {
  align-self: center;
  opacity: 0.2;
  margin-right: 10px;
}
.share-chart-section-title {
  color: black;
  font-weight: bold;
}

.share-chart-section-subtitle {
  letter-spacing: 1.5px;
  font-size: 0.6em;
}

.factor-detail-block {
  color: #414141;
  display: flex;
  justify-content: space-between;
}

.factor-detail {
  align-self: center;
  width: 75%;
}

.factor-description {
  font-size: 0.8em;
}

.factor-result-name {
  font-weight: 900;
  padding-right: 5px;
  font-size: 1rem;
}

.factor-result-description {
  opacity: 0.5;
  padding-left: 5px;
  font-size: 1rem;
}

.factor-result {
  display: flex;
}

.factor-result-value {
  color: #2979ff;
  font-size: 3rem;
  align-self: center;
  font-weight: 700;
  padding-right: 3px;
}

.factor-result-suffix {
  font-size: 1.2rem;
  align-self: center;
  font-weight: 700;
  padding-right: 3px;
  opacity: 0.5;
}

.share-test-button-container {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 6px 0 rgba(0, 0, 0, 0.09);
  position: fixed;
  /* bottom: 60;
  right: 10; */
  background-color: white;
}

.share-test-button {
  padding: 5px;
  display: flex;
  /* width: 200px; */
}

.element-animation {
  animation: animationFrames linear 1.2s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  -webkit-animation: animationFrames linear 1.2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: animationFrames linear 1.2s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 50% 50%;
  -o-animation: animationFrames linear 1.2s;
  -o-animation-iteration-count: infinite;
  -o-transform-origin: 50% 50%;
  -ms-animation: animationFrames linear 1.2s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 50% 50%;
}

.modal-background {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal-image {
  max-width: 80vw;
  min-width: 20vw;
  max-height: 80vh;
  width: fit-content;
  /* background-color: #ffffff77; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@keyframes animationFrames {
  0% {
    transform: translate(0px, 0px);
  }
  15% {
    transform: translate(0px, -25px);
  }
  30% {
    transform: translate(0px, 0px);
  }
  45% {
    transform: translate(0px, -15px);
  }
  60% {
    transform: translate(0px, 0px);
  }
  75% {
    transform: translate(0px, -5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@-moz-keyframes animationFrames {
  0% {
    -moz-transform: translate(0px, 0px);
  }
  15% {
    -moz-transform: translate(0px, -25px);
  }
  30% {
    -moz-transform: translate(0px, 0px);
  }
  45% {
    -moz-transform: translate(0px, -15px);
  }
  60% {
    -moz-transform: translate(0px, 0px);
  }
  75% {
    -moz-transform: translate(0px, -5px);
  }
  100% {
    -moz-transform: translate(0px, 0px);
  }
}

@-webkit-keyframes animationFrames {
  0% {
    -webkit-transform: translate(0px, 0px);
  }
  15% {
    -webkit-transform: translate(0px, -25px);
  }
  30% {
    -webkit-transform: translate(0px, 0px);
  }
  45% {
    -webkit-transform: translate(0px, -15px);
  }
  60% {
    -webkit-transform: translate(0px, 0px);
  }
  75% {
    -webkit-transform: translate(0px, -5px);
  }
  100% {
    -webkit-transform: translate(0px, 0px);
  }
}

@-o-keyframes animationFrames {
  0% {
    -o-transform: translate(0px, 0px);
  }
  15% {
    -o-transform: translate(0px, -25px);
  }
  30% {
    -o-transform: translate(0px, 0px);
  }
  45% {
    -o-transform: translate(0px, -15px);
  }
  60% {
    -o-transform: translate(0px, 0px);
  }
  75% {
    -o-transform: translate(0px, -5px);
  }
  100% {
    -o-transform: translate(0px, 0px);
  }
}

@-ms-keyframes animationFrames {
  0% {
    -ms-transform: translate(0px, 0px);
  }
  15% {
    -ms-transform: translate(0px, -25px);
  }
  30% {
    -ms-transform: translate(0px, 0px);
  }
  45% {
    -ms-transform: translate(0px, -15px);
  }
  60% {
    -ms-transform: translate(0px, 0px);
  }
  75% {
    -ms-transform: translate(0px, -5px);
  }
  100% {
    -ms-transform: translate(0px, 0px);
  }
}
