.ProgressBar {
  width: 100%;
  height: 4px;
  /* border-radius: 5px; */
}

.Progress {
  background-color: #2979ff;
  height: 100%;
  margin: 0;
  border-radius: 5px;
}
