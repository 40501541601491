.symptom-detail-popup-block .item-title {
  white-space: normal !important;
  width: 100%;
  overflow: visible
}

.symptom-detail-popup-block .list textarea.resizable {
  line-height:100%
}

.square-border-checkbox .icon-checkbox {
  border-radius: 5px !important;
}

/* .round-border-radio i.icon {
  display: none!important;
}

.round-border-radio label.item-radio input[type=radio] {
  display: inline-block!important;
  width: 22px;
  height: 22px;
} */

.round-border-radio .icon-radio {
  position: relative !important;
  top: 8px !important;
  left: 0px !important;
  border: var(--f7-checkbox-border-width) solid
    var(--f7-checkbox-inactive-color);
  border-radius: var(--f7-checkbox-border-radius);
  margin-right: 12px;
}

.round-border-radio .icon-radio::after {
  border: var(--f7-checkbox-border-width) solid #007aff !important;
  border-radius: var(--f7-checkbox-border-radius);
  font-family: framework7-core-icons;
  font-style: normal;
}
