.font-title {
    font-size: 36px;
    overflow: hidden;
    text-align: left;
    padding: 0 15px;
    font-weight: bold;
}

.font-type-subtitle {
    font-size: 15px;
    color: #686868;
    padding: 5px 15px 20px;
}

.font-type-tip {
    margin: 5px 0;
    display: flex;
}

.font-type-tip-title {
    font-size: 13px;
    float: left;
    background-color: #deeaff;
    border-radius: 20px;
    color: #2979ff;
    padding: 2px 10px;
    margin-right: 5px;
}

.font-type-tip-content {
    float: left;
}

.font-type-active {
    border: 3px solid #2979ff !important;
}

.font-types {
    display: flex;
}

.font-type {
    flex: 1;
    margin: 10px 20px;
    cursor: pointer;
    box-sizing: border-box;
    padding: 5px 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    border: 3px solid #f9f9f9;
}

.font-type-name {
    font-size: 18px;
    margin: 10px 0 8px;
    font-weight: bold;
}

.font-type-comment {
    font-size: 15px;
    color: gray;
    margin: 4px 0;
}

.font-type-text {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #dcdcdc;
    text-align: left;
    height: 80px;
}

.font-type-large {
    font-size: 24px;
}

.font-type-normal {
    font-size: 18px;
}

.font-type-recommend {
    position: relative;
    top: -18px;
}

.font-type-recommend>div {
    background-color: #ff8f8f;
    border-radius: 18px;
    color: white;
    padding: 5px 0;
    font-size: 12px;
    width: 50px;
    text-align: center;
    margin: 0 auto;
}

.active-type {
    display: none;
    position: relative;
    width: 100%;
    text-align: center;
    top: 30px;
    font-size: 30px;
}

.font-type-active .active-type {
    display: block;
}

.active-type-icon {
    width: 40px;
    height: 40px;
    background-color: #2979ff;
    color: white;
    margin: 0 auto;
    border-radius: 20px;
}

.btn-submit {
    background-color: #2979ff;
    color: white;
    width: 110px;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin: 20px auto;
    border-radius: 20px;
    cursor: pointer;
}