.message-content {
  max-width: 100%!important;
}

.messages {
  background: space;
}

.shufu-text-message-bubble.message-received .message-bubble {
  width: 100%;
  background: #f8f8f8;
  border-radius: 15px 15px 15px 0;
  padding: 10px 16px;
  min-width: unset;
  -webkit-mask-box-image: unset;
  text-align: left;
}
.shufu-text-message-bubble.message-received .message-bubble .message-image {
  max-width: 150px;
}

.shufu-text-message-bubble.message-sent .message-bubble {
  background: #2979ff;
  border-radius: 10px;
  padding: 10px 15px;
  border-radius: 1.5em 0 1.5em 1.5em;
  min-width: unset;
  -webkit-mask-box-image: unset;
  color: white;
  text-align: left;
}

.shufu-text-message-bubble.message .message-avatar {
  opacity: 1;
  width: 35px;
  height: 35px;
  margin-top: 0;
  margin-left: 5px;
  margin-right: 6px;
  border-radius: 20px;
  background-color: #e0e0e0;
  align-self: unset;
  margin-top: 3px;
}

/* instructions样式修改 */
.shufu-text-message-bubble-instructions.message .message-avatar {
  opacity: 1;
  width: 35px;
  height: 35px;
  /* margin-left: 10px; */
  margin-right: 6px;
  border-radius: 20px;
  background-color: #e0e0e0;
  align-self: unset;
  margin-top: 3px;
}
.shufu-text-message-bubble-instructions.message-received .message-bubble {
  width: 100%;
  background: #f8f8f8;
  border-radius: 1.5em 1.5em 1.5em 0;
  padding: 10px 15px;
  min-width: unset;
  -webkit-mask-box-image: unset;
  text-align: left;
}

.shufu-text-message-bubble-question-number {
  position: absolute;
  background: #ffb92e;
  top: -12px;
  padding: 1px;
  border-radius: 15px;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  min-width: 40px;
}
.shufu-text-message-bubble-instructions{
  align-items: flex-start !important;
  white-space: pre-wrap;
  margin: 15px 5px !important;
}
/* 修改回答样式修改 */
.shufu-message-sent-content {
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: flex-end;
  width: 100%!important;
}

.shufu-text-message-bubble {
  align-items: flex-start !important;
  white-space: pre-wrap;
  position: relative;
  margin: 15px 5px !important;
}
.shufu-text-message-bubble-image {
  /* align-items: flex-start !important;
  white-space: pre-wrap;
  position: relative;
  margin: 15px 5px !important; */
  max-width: 95%;
}

.can-edit-tag {
  font-size: 12px;
  color: #2979ff;
  padding: 4px 10px;
  transition: all 0.51s linear;
  overflow: hidden;
  background-color: #f8f8f8;
  border-radius: 1em;
  
}

.shufu-text-message-bubble.message-received.message-typing
  .message-content:after {
  content: none !important;
}

.shufu-text-message-bubble.message-received.message-typing
  .message-content:before {
  content: none !important;
}

/*
文本框外层样式，包裹text和播放按钮的img
*/
.shufu-voice-content {
  display: flex;
  align-items: center;
}
/*
语音播放按钮的样式
*/
.shufu-voice-img {
  width:30px;
  height: 30px;
  margin-left: -10px;
  z-index: 1;
}
/*
症状详情的语音播放按钮的样式
*/
.shufu-voice-symptom-detail-img {
  width:30px;
  height: 30px;
  margin-left: 10px;
  padding-top: 15px;
  z-index: 1;
}

.shufu-text-fold {
  text-decoration: underline;
  color: rgb(255, 185, 33);
  padding-top: 10px;
}