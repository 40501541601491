.history-report-title {
  padding: 20px;
}

.history-report-title, .login-screen-title {
  margin-bottom: 25px;
}

.card-container {
  display: flex;
  padding: 12px 10px;
  justify-content: space-between
}

.info {
  padding-right: 10px;
}

.info, .label {
  font-size:14px;
  min-height: 38px;
  color:#212121;
}
.info, .time {
  font-size:10px;
  color:#8c8c8c;
}

.report-pic {
  padding-top: 17px;
  width: 124px;
  height: 56px;
}

.empty-report {
  font-size: 24px;
  padding: 80px 0;
  text-align: center;
}