.page-content {
  overflow-x: hidden !important;
}

.answerBoardContainer {
  /* height: 30vh; */
  max-height: 50vh;
  background-color: white;
  /* padding-top: 20px; */
  /* padding-bottom: 20px; */
  transition: height 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
  z-index: 10;
  -webkit-box-shadow: 0px 15px 20px 10px rgba(160, 160, 160, 0.4);
  -moz-box-shadow: 0px 15px 20px 10px rgba(160, 160, 160, 0.4);
  box-shadow: 0px 15px 20px 10px rgba(160, 160, 160, 0.4);

  /* background-color: #66ccff; */

  /* display: flex;
  flex-direction: row; */
  overflow-x: hidden;
  border-radius: 1em 1em 0 0;
}

.shufu-answer-button-div {
  box-sizing: border-box;
  display: inline-block;
  padding: 5px 10px;
}
.shufu-answer-button-div-enum-rows {
  flex: 1
}
.shufu-answer-button-div {
  box-sizing: border-box;
  display: inline-block;
  padding: 5px 10px;
}
.shufu-answer-button-div-eight {
  box-sizing: border-box;
  display: inline-block;
  padding: 5px;
}

.shufu-answer-button {
  width: unset; /* remove framework7 css settings */
  outline: none;
  display: inline-block;
  margin: 5px 10px;
  padding: 5px 20px 5px 20px;
  border-radius: 100px;
  border: 1px solid #2979ff;
  color: #2979ff;
  background-color: white;
  /* transition: background-color 0.2s linear; */
  font-size: 16px;
  min-width: 70px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
.button-min-width-auto {
  min-width: auto!important;
}
.shufu-answer-button-enum-rows {
  width: 100%;
  outline: none;
  display: inline-block;
  text-align: center;
  margin: 5px 0;
  padding: 5px 0;
  border-radius: 100px;
  border: 1px solid #2979ff;
  color: #2979ff;
  background-color: white;
  /* transition: background-color 0.2s linear; */
  font-size: 16px;
  min-width: 70px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.shufu-answer-button-information-icon {
  background-color: #2979ff;
  padding: 2px 9px;
  font-weight: bold;
  color: white;
  border-radius: 20px;
}

.shufu-image-answer-button {
  border-radius: 15px;
  padding: 4px;
}

.shufu-answer-image {
  height: 150px;
  width: 100px;
  object-fit: cover;
  border-radius: 11px 11px 0px 0px;
}

.shufu-answer-button:active {
  color: white;
  background-color: #2979ff;
}
/* .shufu-answer-button:hover {
  color: white;
  background-color: #9bbdf8;
} */

.shufu-answer-button-selected,
.shufu-answer-button:active {
  background-color: #deeaff !important;
  color: #2979ff !important;
}

.answer-board-inline-form-wrapper {
  /* height: 100%; */
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  /* overflow-y: hidden; */
  /* box-sizing: border-box; */
  /* background-color: #ffcc66; */
  /* display: flex; */
  flex-direction: column;
  /* flex-direction: row; */
  flex: 1;
  justify-content: center;
}

.answer-board-category-search-wrapper {
  height: 100%;
}
.inline-form-submit-button {
  width: 100%;
  height: 100%;
  /* height: 100px!important; */
  background-color: #2979ff;
  color: white;
  font-size: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.inline-form-submit-button-disabled {
  background-color: #d2d2d2;
}

.inline-form-skip-button {
  width: 100%;
  height: 100%;
  flex: 1;
  /* height: 100px!important; */
  box-sizing: border-box;
  border: 1px solid #2979ff;
  background-color: white;
  color: #2979ff;
  font-size: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
.inline-form-submit-button:active {
  background-color: #9bbdf8;
}
.inline-form-submit-button-disabled:active {
  background-color: #d2d2d2;
}
.inline-form-skip-button:active {
  opacity: 0.5;
}
.answer-board-action-button-wrapper {
  height: 55px;
  background-color: white;
  position: sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.answer-board-answer-button-wrapper {
  /* flex: 1; */
  /* height: 100%; */
  /* height: 100px!important; */
  /* overflow-y: hidden; */
  text-align: center;
  /* display: flex; */
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  /* margin-bottom: 40px; */
  /* padding: 5px; */
}
.answer-board-answer-button-wrapper-left {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.answer-board-inline-form-input {
  padding: 2px;
  font-size: 16px;
  flex: 1;
  color: #002564 !important;
}

.answer-board-inline-form-input-container {
  height: 35px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 100px;
  border: 1px solid #2979FF;
  background-color: #deeaff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.answer-board-inline-form-input-container-invalid {
  border: 1px solid maroon;
}

.answer-board-inline-form-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8492ab;
  opacity: 1; /* Firefox */
}

.answer-board-inline-form-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8492ab;
}

.answer-board-inline-form-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #8492ab;
}

.answer-board-inline-form-input-submit-button {
  width: 55px;
  height: 30px;
  border-radius: 15px;
  margin: 5px;
  background-color: #2979ff;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.answer-board-category-search-sympton-button-wrapper {
  width: 33%;
  display: inline-block;
  /* background-color: red; */
  box-sizing: border-box;
  padding: 5px 15px 5px 15px;
}

.answer-board-category-search-sympton-button {
  color: #2979ff;
  padding: 3px;
  background-color: white;
  outline: none;
  border-radius: 3px;
  border: 1px solid white;
  font-size: 14px;
}
.answer-board-category-search-sympton-button:active {
  color: #2979ff;
  padding: 3px;
  background-color: white;
  outline: none;
  border-radius: 3px;
  border: 1px solid #2979ff;
  font-size: 14px;
}

.progress-bar {
  height: 3px;
  position: absolute;
  left: 0;
  top: 44px;
  bottom: 0;
  z-index: 10000;
  background-color: #2979ff;
  /* border-bottom: 1px solid white; */
  transition: width 0.2s;
  box-shadow: 0px 2px 5px 0px #c6dbff;
}

.shufu-symptom-search-button {
  width: unset;
  border: 1px solid #2979ff;
  border-radius: 2em;
  min-height: 30px;
  color: #007aff;
  font-size: 14px;
  background: white;
  outline: none;
  display: inline-block;
  margin: 6px;
  padding: 7px 17px;
  position: relative;
  white-space: pre-wrap;
  text-align: left;
}

.shufu-symptom-search-button-selected {
  background: #deeaff;
  border: 1px solid #2979ff;
}

/* https://stackoverflow.com/questions/11589590/text-align-justify-inline-block-elements-properly */
/* how to justify buttons to both left and right*/

.symptom-search-show-more-container {
  text-align: right;
  /* margin-top: 15px; */
  /* margin-bottom: 15px; */
  padding: 5px 0;
}

.symptom-search-show-more {
  margin: 5px;
  padding: 10px;
  padding-right: 15px;
  padding-left: 15px;
  color: #2979ff;
  font-size: 16px;
  /* background-color: #f2f2f2; */
  /* border-radius: 20px; */
}

.answer-board-inline-form-wrapper .datepicker {
  background-color: white !important;
  position: relative !important;
}
.answer-board-inline-form-wrapper .datepicker-header {
  color: #2979ff !important;
}

.answer-board-inline-form-wrapper .datepicker-wheel {
  border-top: 1px solid #2979ff !important;
  border-bottom: 1px solid #2979ff !important;
}

.answer-board-inline-form-wrapper .datepicker-navbar-btn {
  color: #2979ff !important;
}
.answer-board-progress-bar-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 44px;
  width: 100%;
  /* background-color: #ddd; */
  box-sizing: border-box;
  padding: 4px;
}
.answer-board-progress-bar-content-no-title {
  /* position: absolute; */
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  /* background-color: #ddd; */
  box-sizing: border-box;
  padding: 4px;
  z-index: 1000;
}
@media (min-width: 900px) {
  .answer-board-progress-bar-content {
    /* width: 33%; */
  }

  .demo-popup {
    width: 90% !important;
    margin-left: -45% !important;
  }
}
.answer-board-progress-bar {
  box-shadow: 0px 2px 5px 0px #c6dbff;
  height: 30px;
  display: flex;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  align-items: center;
  box-shadow: 0px 0px 5px 2px #2979ff;
  padding: 0 10px;
  background-color: #fff;
}
.answer-board-progress-bar-line {
  flex: 1;
  position: relative;
}
.answer-board-progress-bar-num {
  padding-left: 20px;
}
.bar-line-bg {
  position: absolute;
  top: -2px;
  left: 0;
  height: 6px;
  width: 100%;
  border-radius: 3px;
  background-color: #f8f8f8;
}
.bar-line-progress {
  position: absolute;
  top: -2px;
  left: 0;
  height: 6px;
  border-radius: 3px;
  background-color: #2979ff;
}
.answer-date-inputs-text {
  line-height: 36px;
  margin: 0 5px;
}
.answer-date-inputs-div {
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  border: solid 1px #2979ff;
  background-color: #deeaff;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.multiple-choice-checkmark {
  position: absolute;
  width: 20px;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  height: 20px;
  background-color: #ffb92e;
  border-radius: 20px;
  line-height: 20px;
  color: #ffffff;
}
.question-icon {
  max-width: 20px;
  max-height: 20px;
  position: relative;
  top: 3px;
  margin: 0 6px;
}

.down-arrow {
  height: 50px;
  width: 50px;
  position: fixed;
  bottom: 60px;
  right: 10px;
  color: rgb(41, 121, 255);
  border-radius: 25px;
  background-color: #2979FF;
  opacity: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.shufu-image-answer-content {
  position: relative;
}

.shufu-image-answer-full-screen-icon-content {
  position: absolute;
  top: -13px;
  right: -13px;
  padding: 5px;
  width: 20px;
  height: 20px;
  border: 1px solid #2979ff;
  border-radius: 15px;
  background-color: #2979ff;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shufu-image-answer-full-screen-icon {
  transform: rotate(90deg);
}
.shufu-image-fullscreen-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.shufu-image-answer-full-screen {
 width: 100%;
}



