.static-form-popup .page-content {
  background: white;
}

.static-form-popup .card {
  box-shadow: none;
}

.static-form-popup .list {
  background: #f9f9f9;
  border: 1px solid #f9f9f9;
  border-radius: 8px;
  padding: 10px;
  margin-top: 17px;
}

.static-form-popup-section-title {
  color: black;
  font-size: 16px;
  font-weight: bold;
  padding-top: 15px;
  padding-left: 15px;
}

.static-form-popup .item-inner:after {
  content: none;
}

.static-form-popup .item-title {
  white-space: unset;
  width: 100%
}

.static-form-popup .list textarea.resizable {
  line-height:100%
}

.is-required-asterisk {
  color: red;
  padding-left: 5px;
}
