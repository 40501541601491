.successful-register {
  display: flex;
  height: 60vh;
  flex-direction: column;
  -ms-flex-direction: column;
  justify-content: space-around;
}

.successful-text {
  line-height: 40px;
  font-size: 28px;
  text-align: center;
  font-weight: 500;
}

.successful-img {
  text-align: center;
  
}