.page-questionnaire {
  padding: 20px;
  display: flex;
  flex-direction: column;
  -ms-flex-direction: column;
  height: inherit;
  overflow: auto;
}

.title {
  text-align: center;
  font-weight: bold;
  font-size: x-large;
}

.desc {
  text-indent: 2rem;
}

.question_row {
  margin: 5px 0;
  letter-spacing: 3px;
  font-size: large;
}

.question_star{
  color: #ff0000;
}

.question_index {
  font-weight: 500;
}

.question {
  font-weight: 500;
}

.phone-number {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  -ms-flex-direction: column;
  align-items: center;
}