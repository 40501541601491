.chatContainer {
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  /* padding-top: 40px; */
  box-sizing: border-box;
}

.messageListWrapper {
  flex: 1;
  justify-content: flex-end;
  flex-direction: column;
  display: flex;
  background-color: white;
  background: space;
  background-size: 100% 100%;
  /* overflow-y: scroll; */
  /* overflow-y: auto; */
  overflow: hidden;
  scroll-behavior: smooth;
  position: relative;
}

.massageListInnerWrapper {
  padding-bottom: 20px;
  max-height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.upload-bug-report-button {
  position: absolute;
  z-index: 999;
  color: white;
  margin-top: 20%;
  right: 0px;
  border-radius: 20px 0px 0px 20px;
  box-shadow: rgb(178, 178, 178) 2px 2px 10px;
  background-color: skyblue;
  font-size: 16px;
  height: auto;
  padding: 0px 5px 0px 10px;
}

.upload-bug-report-button:hover {
  opacity: .5;
}

/* 重写action样式，解决下划线丢失问题 */

.actions-button:after, .actions-label:after {
  height: 2px !important;
}

.actions-button {
  background-color: #fff !important
}

.chat-title-right {
  font-size: 14px;
}

.chat-title-right-icon{
  font-size: 16px;
}