.answer-date-input-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.answer-date-inputs-text {
  line-height: 36px;
  margin: 0 5px;
}
.answer-date-inputs-div {
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  border: solid 1px #2979ff;
  background-color: #deeaff;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.answer-date-inputs-div-invalid {
  border-color: maroon !important;
}
